var CitySelectorNew = function($element, options){
    this.element = $element;

    this.defaults = {
        options: [],
        rows: 4
    };
    this.options = jQuery.extend({}, this.defaults, options);

    this.input = this.element.find('[data-role="input"]');
    this.list = this.element.find('[data-role="list"]');
    this.hint = this.element.find('[data-role="hint"]');
    this.submit = this.element.find('[data-role="submit"]');
    this.city = this.element.find('[data-role="city"]');
    this.links = this.element.find('[data-role="links"]');
    this.back = this.element.find('[data-role="back"]');
    this.selection = null;

    this.init();
};

CitySelectorNew.prototype = {
    defaults: {},

    init: function ()
    {
        var _self = this;

        if (this.options.options) {
            var cols = Math.floor(this.options.options.length / this.options.rows);
            var remain = this.options.options.length % this.options.rows;
            var index = 0;

            for (var a = 0; a < cols; a++) {
                var ul = $('<ul />');
                var items = this.options.rows;
                if (remain) {
                    items++;
                    remain--;
                }
                for (var b = 0; b < items; b++) {
                    var el = $('<li />', {'data-id' : this.options.options[index].id, 'data-role': 'city'});
                    el.text(this.options.options[index++].name);
                    el.prepend(svg('arrow'));
                    ul.append(el);
                }

                this.list.append(ul);
            }

            this.list.on('click', 'li', function() {
                var id = $(this).attr('data-id');
                _self.selection = _self.options.options.filter(function(itm) {
                    return itm.id == id;
                })[0];

                _self.slideLeft();
            });

            this.input.on('focus', function() {
                _self.openOptions();
            }).on('keyup', function(e) {
                var val = _self.input.val();
                var hint = '';
                var matching = val ? _self.options.options.filter(function(itm) {
                    return new RegExp('^' + val, 'i').test(itm.name);
                }) : [];
                var cancel = false;

                _self.list.find('li').removeClass('active-telep');

                if (matching.length) {
                    hint = val.substring(0, 1) + matching[0].name.substring(1);
                    cancel = true;

                    _self.list.find('li[data-id="' + matching[0].id + '"]').addClass('active-telep');
                    _self.selection = matching[0];

                }
                else {
                    _self.selection = null;
                }

                _self.hint.val(hint);

                return cancel;
                
            });

            this.submit.on('click', function() {
                _self.slideLeft();
            });

            this.back.on('click', function() {
                _self.slideRight();
            });

            this.element.on('click', function(e) {
                var target = $(e.target).data('role');
                if (target == 'input' || target == 'hint' || target == 'submit' || target == 'city') {
                    e.stopPropagation();
                }
            });

            $(document).on('click', function() {
                _self.closeOptions();
            });
        }
    },

    openOptions: function() {
        var _self = this;

        this.element.addClass('show-options');
        setTimeout(function() {
            _self.list.addClass('show-options');
        }, 100);
    },

    closeOptions: function() {
        var _self = this;

        this.list.removeClass('show-options');
        setTimeout(function() {
            _self.element.removeClass('show-options');
        }, 100);
    },

    slideLeft: function() {
        var _self = this;
        _self.originalHeight = _self.element.height();

        if (this.selection) {
            this.input.val(this.selection.name);
            this.city.text(this.selection.name);
            
            this.links.empty();

            var trans = [];

            translate(trans, function() {                
                console.log(_self.selection.categories['base_link'].link);
                var ul = $('<ul/>');
                var itm = $('<li />', {class: 'dhk-link'});
                var link = $('<a />', {href: _self.selection.categories['base_link'].link, target: '_blank'});
                var title = $('<span />', {class: 'dhk-title'}).text('Hulladékgazdálkodás');                
                var text = $('<span />', {class: 'dhk-text'}).html('A hulladékgazdálkodási kötelező közszolgáltatást a Debreceni Hulladék Közszolgáltató Nonprofit Kft. végzi, kattintson a részletekért!');                

                link.append(title);  
                link.append(text);              
                itm.append(link);

                ul.append(itm);

                _self.links.append(ul);

                window.getComputedStyle(ul.get(0)).opacity;
                _self.links.children().addClass('showing');

            });

            this.element.addClass('slide');
        }
    },

    slideRight: function() {
        var _self = this;

        this.input.val('');
        this.hint.val('');
        this.selection = null;

        setTimeout(function() {
            _self.element.removeClass('slide');
            _self.links.children().removeClass('showing');
        }, 200);
    }

};