var Accordion = function($element, options){
    this.element = $element;

    this.defaults = {
        singleActiveItem: false,
        activeIndex: undefined,
        fromWidth: 0,
    };
    this.options = jQuery.extend({}, this.defaults, options);

    this.items = this.element.find('> [data-role="accordion-item"]');
    if (this.items.length) {
        this.init();
    }
};

Accordion.prototype = {
    defaults: {},
    windowWidth: undefined,
    init: function ()
    {
        this.windowWidth = window.innerWidth;
        if (this.options.activeIndex != undefined) {
            this.activate(this.options.activeIndex);
        }

        this.addDOMEvents();

        $('.accordion-default-open').each(function(){
            $(this).find('[data-role="accordion-title"]').trigger('click');
        });
    },
    addDOMEvents: function() {
        var _self = this;
        
        this.items.children('[data-role="accordion-title"]').on('click', function() {
            if (_self.options.fromWidth == 0 || (_self.options.fromWidth && _self.options.fromWidth >= _self.windowWidth)) {
                _self.activate($(this).closest('[data-role="accordion-item"]'));
            }
        });

        if (typeof _self.options.fromWidth !== 'undefined') {
            var resizeFn = debounce(function () {
                if (_self.windowWidth != window.innerWidth) {
                    _self.windowWidth = window.innerWidth;
                    
                    if (_self.options.fromWidth && _self.windowWidth > _self.options.fromWidth) {
                        _self.remove();
                    }
                }
            }, 100);

            window.addEventListener('resize', resizeFn);
        }
    },
    activate: function(act) {
        if (act) {
            if (this.options.singleActiveItem) {
                this.element.find('.active').removeClass('active');
                setTimeout(function() {
                    $(act).toggleClass('active').children('[data-role="accordion-content"]').slideToggle();
                    $(document).trigger('shoppingstreet.accordionitemopen', act);
                }, 500);
            } else {
                $(act).toggleClass('active').children('[data-role="accordion-content"]').slideToggle()
                $(document).trigger('shoppingstreet.accordionitemopen', act);
            }
        }
    },
    remove: function() {
        this.items.each(function(){
            $(this).find('[data-role="accordion-content"]').removeAttr('style');
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(document).trigger('shoppingstreet.accordionitemclose', $(this));
            }
        })
    }
};
