(function(wnd) {
    function selectiveMap($element, options) {
        var default_options = {
            show_on_scroll : true,
            markers : [],
            city_name : '',
            use_regions : true,
            initialized: false
        };
    
        this.$element = $element;
        this.options = $.extend({}, default_options, options);
    
        this.$map = $element.find('.map');
        this.$selective_map = $element.find('.selective-map');
        this.$ac_input = $element.find('.autocomplete .autocomplete-input');
    
        this.initialized = false;
        this.map_created = false;
        this.tolerance = 100;
        this.resizing = false;
        this.scrolling = false;
    
        this.init();
    };
    
    selectiveMap.prototype.init = function() {
        var _self = this;
    
        if(_self.initialized !== false || _self.$element.data('map-initialized') === true) return false;
    
        _self.$element.data('map-initialized', true);
    
        _self.$element.data('selective-map-obj', _self);
        
        //show map if scroll to
        if(_self.options.show_on_scroll) {
            var regionTop = 0, regionBottom = 0;
            
            $(document).on('aksd.mapinit', function() {		
                _self.options.initialized = true;
                $(window).on('resize', __mapresize).on('scroll', __mapscroll);
                __mapresize();
            });
    
            if (typeof google_map_loaded !== 'undefined' && google_map_loaded) {
                $(window).on('resize', __mapresize).on('scroll', __mapscroll);
                __mapresize();
            } else {
                $(document).on('aksd.mapinit', function() {		
                    $(window).on('resize', __mapresize).on('scroll', __mapscroll);
                    __mapresize();
                });
            }
    
            function __mapresize() {
                if (_self.resizing) return;
    
                _self.resizing = true;
    
                var region = $('.selective-map');
                
                regionTop = region.offset().top;
                regionBottom = regionTop + region.outerHeight(true);
    
                _self.resizing = false;
    
                __mapscroll();
            }
    
            function __mapscroll() {
                if (_self.scrolling) return;
                _self.scrolling = true;
    
                var viewTop = $(window).scrollTop(),
                    viewBottom = viewTop + $(window).height();
    
                if (regionTop >= viewTop - _self.tolerance && regionBottom <= viewBottom + _self.tolerance) {
                    $(window).off('resize', __mapresize).off('scroll', __mapscroll);
                    _self.createMap();
                }
    
                _self.scrolling = false;
            }
        }
        else {
            $(document).on('aksd.mapinit', function() {
                _self.createMap();
            })
        }
        
    };
    
    selectiveMap.prototype.createMap = function() {
        var _self = this;
    
        if(_self.map_created !== false || _self.$element.data('map-created') === true) return false;
    
        _self.$element.data('map-created', true);
    
        var markers = [];
        for(var i = 0; i < _self.options.markers.length; i++) {
            var marker_data = _self.options.markers[i];
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(marker_data.lat, marker_data.lng),
                icon: marker_base_url + marker_data.marker,
            });
            
            markers.push(marker); 
        }
        
        _self.setMarkers(markers);
        
        _self.$selective_map.removeClass('loading section-loading');
    };
    
    selectiveMap.prototype.setMarkers = function(markers) {
        var _self = this;
    
        var options = mapOptions;
        options.zoom = 10;
        
        if (_self.options.use_regions) {
            options.data = {markers: markers};
            _self.map = _self.$map.markerregions(options);
        } else {
            var settings = $.extend({
                data: {markers: []}        
            }, options);
            
            var selectiveMapOptions = {
                zoom: settings.zoom,
                center: settings.center,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scrollwheel: false,
                mapTypeControl: false,
                styles: settings.styles
            }
            
            _self.map = new google.maps.Map(document.getElementById(_self.$element.attr('id')), selectiveMapOptions);
            var markerBounds = new google.maps.LatLngBounds();
    
            for (var i = 0; i < markers.length; i++) {    
                markers[i].setMap(_self.map);
                markerBounds.extend(markers[i].getPosition());
            }

            if (markerBounds.getNorthEast().equals(markerBounds.getSouthWest())) {
                var extendPoint1 = new google.maps.LatLng(markerBounds.getNorthEast().lat() + 0.01, markerBounds.getNorthEast().lng() + 0.01);
                var extendPoint2 = new google.maps.LatLng(markerBounds.getNorthEast().lat() - 0.01, markerBounds.getNorthEast().lng() - 0.01);
                markerBounds.extend(extendPoint1);
                markerBounds.extend(extendPoint2);
             }

            _self.map.fitBounds(markerBounds);
            
            new MarkerClusterer(_self.map, markers, {
                'styles': [{
                    url: marker_base_url + 'clusterer.svg',
                    height: 48,
                    width: 48,
                    textColor: '#ffffff',
                    textSize: 18
                }],
                'ignoreHidden' : true
            })
        }
    };
    
    selectiveMap.prototype.zoomRegion = function(region) {
        var _self = this;
    
        if(_self.$element.data('map-initialized') == true) {
            _self.$map.zoomregion(region);
            var ac_obj = _self.$ac_input.data('ac_obj');
            if(ac_obj != undefined) {
                _self.$ac_input.trigger( "blur" );
            }
        }
    };
    
    selectiveMap.prototype.doGeoCode = function(address) {
        var _self = this;
    
        if(_self.$element.data('map-initialized') == true) {
    
            if($.trim(address) === '')
                return false;
    
            if (address.indexOf(' ') == -1 && !address.match(/utca$/i)) {
                address += ' utca';
            }
    
            geocoder = new google.maps.Geocoder();
            geocoder.geocode({
                'address': _self.options.city_name + ' ' + address
            }, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    _self.$map.findclosest(results[0].geometry.location);
                }
            });
        }
        
    };

    wnd.SelectiveMap = selectiveMap;
})(window);
