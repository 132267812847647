$(document).ready(function() {

	$('.date-mask').mask('9999');

	$('.register-toggle').click(function() {
		$('.register-form').slideToggle();
	});

	$('.fileupload').each(function(ind, el) {
		var th = $(el);
		var input = th.find('.input');
		var inputName = input.attr('name');
		var progressbar = th.find('.progress');
		var uploaded = th.find('.file-uploaded');
		var feedback = th.find('.upload-feedback');

		input.fileupload({
			dataType: 'json',
			url: ajax_controller + 'fileupload/' + inputName,
			disableImagePreview: true,
			add: function (e, data) {
				th.data('filename', data.files[0].name);
				progressbar.children('.bar').css('width', '0');
				progressbar.show();
				data.submit();
				
				if (feedback.length) {
					feedback.removeClass('error-box');
					feedback.removeClass('success-box');
				}
	        },
			done: function (e, data) {

				var id = $('#allaskereso_id').val();

				translate(['fileupload_success'], function() {
					$.each(data.result[inputName], function (index, file) {
					    progressbar.hide();
						
						if (file.error) {
							feedback.addClass('error-box');
							showMessage(file.error, 'error', feedback);
						}
						else {
							var uploadedName = th.data('filename');
							th.data('filename', file.name);

							var message = dictionary['fileupload_success'] + ' <strong>' + uploadedName + '</strong>';
							$.post(ajax_controller + 'save_upload', {id: id, field: inputName, value: th.data('filename')}, function(ret) {
								
								if (!ret.success) {
									message = ret.message; 
								}
								else {
					                if (!th.hasClass('image-upload')) {
					                	var link = $('<a />', {href: file.url, target: '_blank'}).text(th.data('filename'));
					                	uploaded.find('.file-name').empty().append(link); 
					            	}
					            	else {
					            		th.find('.file-preview').append($('<img />', {src: file.url}) );
					            	}
					                th.addClass('upload-complete');
								}

								let feedbackClass = (ret.success ? 'success' : 'error') + '-box'
								feedback.addClass(feedbackClass);
								showMessage(message, ret.success ? 'success' : 'error', feedback);

							}, 'json');
			            }
		            });
				});
	        },
	        fail: function(e, data) {
				console.log(data);
				progressbar.hide();
				showMessage('Unknown error, please try it later!', 'error', feedback);
	        },
	        progress: function (e, data) {
		        var progress = parseInt(data.loaded / data.total * 100, 10);
		        progressbar.children('.bar').css(
		            'width',
		            progress + '%'
		        );
		    }
		});

		th.find('.file-delete a').click(function() {
			var id = $('#allaskereso_id').val();

			$.post(ajax_controller + 'filedelete', {id: id, field: inputName, file: th.data('filename')}, function(ret) {
				if (ret.success) {
	                th.removeClass('upload-complete');
	                th.find('.file-preview img').remove();
				}
				showMessage(ret.message, ret.success ? 'success' : 'error', feedback);
			}, 'json');
		});
	}); 


	$('.jelentkezes-form form').on('pw.formsuccess', function() {
		var toggle = $(this).find('[name="jelentkezes_toggle"]');
		var submit = $(this).find('.form-submit');
		var val = toggle.val();
		var text = val === '1' ? 'jelentkezes_visszavonas' : 'jelentkezes';

		translate([text], function(d) {
			submit.val(d[text]);
			toggle.val(val ? 0 : 1);
		});
	});

});