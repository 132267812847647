$(document).ready(function() {

	$('#vote-form').submit(function() {
		$.ajax({
			url: ajax_controller + 'vote',
			dataType: 'json',
			type: 'POST',
			data: $(this).serialize(),
			beforeSend: function() {
				$('.vote-stats').hide();
				$('.vote-feedback').hide();
				$('#vote-form').find('.button').removeClass('has-error');
			},
			success: function(response) {
				if (response.success) {
					$('.vote-section .form-content').hide();
					$('.vote-stats').html(response.html);
					$('.vote-stats').show();
				} else {
					$('#vote-form').find('.button').addClass('has-error');
					$('.vote-feedback').html(response.html).slideDown();
				}
			}
		});
		
		return false;
	});

	$('.vote-stat-toggle').click(function() {
		var cont = $('.vote-stats');

		if (cont.children().length) {
			cont.slideToggle();
		} else {
			$.post(ajax_controller + 'vote_stat', {form: $(this).data('form-id') }, function(ret) {				
				cont.html(ret);
			});
		}
	});

	$('.vote-option .mdl-radio__button, .vote-option .mdl-checkbox__input').change(function() {
		var th = $(this).parent().next('.fillable');
		if (th.length && $(this).prop('checked')) {
			th.slideToggle();
		}

	}).change();

});