(function(wnd) {
    function SecondaryMobileMenu(container) {
        try {
            if (!container || container.length === 0) {
                throw 'Container is required!';
            }

            this.container = container;
            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    SecondaryMobileMenu.prototype = {
        container: undefined,
        opened: false,
        type: '',
        init: function() {
            this.type = this.container.data('type');

            this.addDOMEvents();
        },
        addDOMEvents: function() {
            var _self = this;

            $('[data-purpose="secondary-menu-trigger"]').on('click', function() {
                if ($(this).data('type') === _self.type) {
                    _self.changeVisibility(true);
                }
            });

            $('.secondary-menu-overlay[data-type="'+this.type+'"]').on('click', function() {
                _self.changeVisibility(false);
            });

            this.container.find('.close-btn').on('click', function() {
                _self.changeVisibility(false);
            });
        },
        changeVisibility: function(open) {
            if (open) {
                $(document).trigger('aksd.close_mobile_menu');
                $('html, body').addClass('overflow-hidden');
                this.container.addClass('opened');
                $('.secondary-menu-overlay[data-type="'+this.type+'"]').addClass('visible');

            } else {
                $('html, body').removeClass('overflow-hidden');
                this.container.removeClass('opened');
                $('.secondary-menu-overlay[data-type="'+this.type+'"]').removeClass('visible');
            }
            
            this.opened = open;
        }
    }

    wnd.SecondaryMobileMenu = SecondaryMobileMenu;
})(window);