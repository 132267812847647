var opened_modal = false;

var $jqmodal_overlay = $('<div/>', {
	'class' : 'jqmodal-overlay'
});

var jQModal = function($element, options) {

	var default_options = {
		fitToContent: false,
		beforeOpen: null,
        afterOpen: null,
        beforeClose: null,
        afterClose: null,
        useHash: false
	};

	this.$element = $element;
	this.options = $.extend({}, default_options, options);

	this.$body = $('body');

	this.initialized = false;
	this.event_str = 'jqmodal' + Math.floor(Math.random() * 100) + 1;

	this.init(); 
};

jQModal.prototype.init = function() {
	var _self  = this;

	if(_self.initialized !== false)
		return false;

	_self.scrollbar_width = getScrollBarWidth();

	//create content
	createModal();

	//create close button
	createCloseButton();

	//find all anchor to this popup
	_self.$links = $('[data-modal-link="#' + _self.$element.attr('id') + '"]');
	_self.$links.on('click.' + _self.event_str, createClickEvent);

	//set base url
	setBaseUrl();

	_self.$element.data('jqmodal', _self);

	// var resizeHandler = debounce(function() {
	// 	_self.$element.css({width: ''});
	// 	if (_self.options.fitToContent) {
	// 		var pad = (parseInt(_self.$element.css('paddingLeft'), 10));
	// 		_self.$element.css({width: _self.$element.children().width() - pad * 2 });
	// 	}
 //    }, 250);

	// $(window).resize(resizeHandler).resize();


	/** create click event **/
	function createClickEvent(e) {
		e.preventDefault();

		_self.params = $(e.target).data();

		_self.open();
	}

	function setBaseUrl() {

		if (_self.options.useHash) {
			var url = window.location.href;
			var index = url.indexOf('#');
			var url_hash = null;

			if(index === -1) {
				_self.base_url = url;
			}
			else {
				_self.base_url = url.substring(0, index);

				url_hash = url.substring(index);
			}

			_self.url = '#' + _self.$element.attr('id');

			//open the necessary popup
			if(url_hash === _self.url) {
				$('html, body').scrollTop(0);
				_self.open();
			}
		}

	}

	/** create modal content **/
	function createModal() {

		_self.$modal = $('<div/>', {
			'class' : 'jqmodal'
		});

		if (_self.$element.attr('data-lazy-render')) {
			_self.$modal.addClass('lazy-render');
		}

		//append content to modal
		/*var $orig_element = _self.$element;
		_self.$element = $orig_element.clone();
		$orig_element.remove();*/

		_self.$modal.append(
			_self.$element.addClass('modal-content')
		);

		if (_self.$element.hasClass('fullmodal')) {
			_self.$modal.addClass('fullmodal');
			_self.$element.removeClass('fullmodal');
		}

		//append modal to body
		_self.$body.append( _self.$modal );
	}

	/** create close button **/
	function createCloseButton() {
		_self.$close = $('<a/>', {
			'class' : 'jqmodal-close'
		});

		_self.$element.append( _self.$close );
	}

	/** get scrollbar width **/
	function getScrollBarWidth () {
		var inner = document.createElement('p');
		inner.style.width = "100%";
		inner.style.height = "200px";

		var outer = document.createElement('div');
		outer.style.position = "absolute";
		outer.style.top = "0px";
		outer.style.left = "0px";
		outer.style.visibility = "hidden";
		outer.style.width = "200px";
		outer.style.height = "150px";
		outer.style.overflow = "hidden";
		outer.appendChild (inner);

		document.body.appendChild (outer);
		var w1 = inner.offsetWidth;
		outer.style.overflow = 'scroll';
		var w2 = inner.offsetWidth;
		if (w1 == w2) w2 = outer.clientWidth;

		document.body.removeChild (outer);

		return (w1 - w2);
	};

	
};

jQModal.prototype.callback = function(callback, onFinished) {
	var _self = this;
	var ret = true;
	
	var type = typeof callback;
	// console.log(callback + ' is ' + type);

	if(type === 'function') {
		ret = callback.call( _self.$element, _self.params );
	}
	else if(type === 'string') {
		// var fn = window[type];
		// if(typeof fn === 'function') {
		//     fn( _self.$element );
		// }
		try {
			var fn = eval(callback);
			if(typeof fn === 'function') {
			    ret = fn( _self.$element, _self.params );
			}
		}
		catch (e) {
		}
	}

	if (typeof onFinished == 'function') {
		onFinished();
	}

	return ret;

};

jQModal.prototype.setModalPosition = function() {
	var _self = this;

	var vph = viewportSize.getHeight();
	var elh = _self.$element.outerHeight() + parseInt(_self.$element.css('margin-top')) + parseInt(_self.$element.css('margin-bottom'));

	if(elh >= vph)
		_self.$element.css('top', 'auto');
	else {
		_self.$element.css('top', (vph - elh) / 2);
	}
};

jQModal.prototype.open = function() {
	var _self = this;

	if(opened_modal !== false) {
		$(opened_modal).data('jqmodal').close();
		// return false;
	}

	_self.callback( _self.options.beforeOpen, function() {
		opened_modal = _self.url;

		_self.setModalPosition();

		_self.body_right_padding = _self.$body.css('padding-right');

		// _self.$body.addClass('overlay-open');
		$('html').addClass('overlay-open');
					// .css('padding-right', _self.scrollbar_width);
		_self.$modal.addClass('opened');

		/** init events **/
		_self.$close.on('click.' + _self.event_str, function() {
			_self.close();
		});
		_self.$modal.on('click.' + _self.event_str, function(e) {
			if($(e.target).is(_self.$modal)) {
				_self.close();
			}
		});

		if (_self.options.useHash) {
			history.pushState( null, null, _self.base_url +  _self.url);
		}
		setTimeout(function() {
			_self.callback( _self.options.afterOpen );
		}, 300);
	});

};

jQModal.prototype.close = function() {
	var _self = this;

	_self.callback( _self.options.beforeClose );
	if (_self.options.beforeClose) {
		setTimeout(function() {
			__doClose();
		}, 500);
	}
	else {
		__doClose();
	}

	function __doClose() {
		// _self.$body.removeClass('overlay-open');
		$('html').removeClass('overlay-open');
			// .css('padding-right', _self.body_right_padding);
		_self.$modal.removeClass('opened');

		/** remove events **/
		_self.$close.off('click.' + _self.event_str);
		_self.$modal.off('click.' + _self.event_str);

		_self.callback( _self.options.afterClose );

		if (_self.options.useHash) {
			history.pushState( null, null, _self.base_url);
		}

		opened_modal = false;
	}
};


$(document).ready(function() {
	$('body').append($jqmodal_overlay);

	$('.modal-content').each(function(ind, el) {
		new jQModal($(this), {
	        beforeOpen: $(this).data('before-open'),
	        afterOpen: $(this).data('after-open'),
	        beforeClose: $(this).data('before-close'),
	        afterClose: $(this).data('after-close'),
	        fitToContent: $(this).data('fit'),
	        useHash: $(this).data('use-hash')
	    });
	});
});

