/**
 * Scroll Start Stop for jQuery by Toni Almeida, based in the work of James Padolsey
 * ---
 * @author Toni Almeida (http://promatik.no.sapo.pt)
 * @version 1.0
 * @updated 13-Ago-13
 * @forked James Padolsey (https://github.com/padolsey)
 * ---
 * @info https://github.com/promatik/jQuery-Scroll-StartStop
 */
(function(){var e=jQuery.event.special,t="D"+ +(new Date),n="D"+(+(new Date)+1),r=false,i=300;e.scrollstart={setup:function(){var e,n=function(t){e=null},s=function(t){if(e)clearTimeout(e);else jQuery.event.simulate("scrollstart",this,arguments);e=setTimeout(r?s:n,i)};jQuery(this).on("scroll",s).data(t,s)},teardown:function(){jQuery(this).off("scroll mousedown mouseup",jQuery(this).data(t))}};e.scrollstop={setup:function(){var e,t=function(t){e=null},s=function(n){if(e)clearTimeout(e);e=setTimeout(r?s:function(){t();jQuery.event.simulate("scrollstop",this,arguments)},i)};jQuery(this).on("scroll",s).data(n,s)},teardown:function(){jQuery(this).off("scroll",jQuery(this).data(n))}};jQuery(this).on("mousedown",function(e){r=true}).on("mouseup",function(e){r=false})})()
